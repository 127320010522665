import "./Input.css";

import React from "react";

const Input = (props) => {
  return (
    <div className="inputComponent">
      <label style={{ color: props.name === "#" ? "#fff" : "#000" }}>
        {props.name}
      </label>
      <div className="inputWrapper" error={props.error ? "true" : "false"}>
        <input
          id="input"
          type={"text"}
          placeholder={props.placeholder}
          onChange={props.handler}
          maxLength={props.maxLength}
          onBlur={props.blurHandler}
        />
      </div>
      {props.error && (
        <p className="errorText" style={{ marginTop: "0.4rem 0" }}>
          {props.errorMessage}
        </p>
      )}
    </div>
  );
};

export default Input;
