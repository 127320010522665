import CardBack from "./components/CardBack";
import CardFront from "./components/CardFront";
import "./App.css";
import Form from "./components/Form";
import Confirmation from "./components/Confirmation";
import { useState } from "react";

function App() {
  let [confirmation, setConfirmation] = useState(false);
  return (
    <div className="app">
      <div className="cardContainer">
        <CardFront />
        <CardBack />
      </div>
      {!confirmation && <Form setConfirmation={setConfirmation} />}
      {confirmation && <Confirmation />}
      <footer>
        <p>
          Challenge by
          <a
            href="https://www.frontendmentor.io?ref=challenge"
            target="_blank"
            rel="noreferrer"
          >
            Frontend Mentor
          </a>
          . Coded by <a href="https://github.com/fracergu">Francisco Cerdán</a>.
        </p>
      </footer>
    </div>
  );
}

export default App;
