import React from "react";
import iconComplete from "../assets/img/icon-complete.svg";
import "./Confirmation.css";

const Confirmation = () => {
  return (
    <div className="confirmation">
      <img src={iconComplete} alt="completed" />
      <h1>THANK YOU!</h1>
      <p>We've added your card details</p>
      <button className="confirmButton">Confirm</button>
    </div>
  );
};

export default Confirmation;
