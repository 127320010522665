import React, { useState } from "react";
import {
  setCardCVV,
  setCardExpMonth,
  setCardExpYear,
  setCardNameholder,
  setCardNumber,
} from "../store/card";
import "./Form.css";
import Input from "./Input";

const Form = (props) => {
  let [nameHolderError, setNameHolderError] = useState(false);
  let [cardNumberError, setCardNumberError] = useState(false);
  let [cvvError, setCvvError] = useState(false);
  let [monthError, setMonthError] = useState(false);
  let [yearError, setYearError] = useState(false);

  const setLeadZero = (e) => {
    if (Number(e.target.value) < 10 && e.target.value.length === 1) {
      e.target.value = `0${e.target.value}`;
    }
  };

  const formHasErrors = () => {
    return nameHolderError || cardNumberError || cvvError || monthError;
  };

  const handleCardNameholderBlur = (e) => {
    setNameHolderError(e.target.value.length < 3);
  };

  const handleCardNameholderChange = (e) => {
    setCardNameholder(e.target.value);
    setNameHolderError(e.target.value.length < 3);
  };

  const handleCardNumberBlur = (e) => {
    setCardNumberError(e.target.value.length !== 19);
  };

  const handleCardNumberChange = (e) => {
    e.target.value = e.target.value
      .replace(/[^0-9]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
    setCardNumber(e.target.value);
    setCardNumberError(e.target.value.length !== 19);
  };

  const handleMonthChange = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    setCardExpMonth(e.target.value);
    setMonthError(Number(e.target.value) > 12 || Number(e.target.value) < 1);
  };
  const handleMonthBlur = (e) => {
    setLeadZero(e);
    setCardExpMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    setCardExpYear(e.target.value);
    const year = Number(
      new Date(Date.now()).getFullYear().toString().slice(2, 4)
    );
    setYearError(Number(e.target.value) < year);
  };

  const handleYearBlur = (e) => {
    setLeadZero(e);
    setCardExpYear(e.target.value);
  };

  const handleCardCVVChange = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    setCardCVV(e.target.value);
    setCvvError(e.target.value.length < 3);
  };

  const handleCardCVVBlur = (e) => {
    setCvvError(e.target.value.length < 3);
  };

  const handleConfirmationButton = () => {
    props.setConfirmation(true);
  };

  return (
    <div className="form">
      <Input
        name="cardholder name"
        handler={handleCardNameholderChange}
        blurHandler={handleCardNameholderBlur}
        errorMessage="Needs to have at least 3 characters."
        error={nameHolderError}
      />
      <Input
        name="card number"
        handler={handleCardNumberChange}
        blurHandler={handleCardNumberBlur}
        errorMessage="Needs to have 16 digits."
        error={cardNumberError}
        maxLength="19"
      />
      <div className="flex" style={{ gap: "1rem" }}>
        <div className="flex" style={{ width: "50%", gap: "0.5rem" }}>
          <Input
            name="Month"
            placeholder="MM"
            handler={handleMonthChange}
            blurHandler={handleMonthBlur}
            errorMessage="Inv. month"
            error={monthError}
            maxLength="2"
          />
          <Input
            name="Year"
            placeholder="YY"
            handler={handleYearChange}
            blurHandler={handleYearBlur}
            errorMessage="Inv. year"
            error={yearError}
            maxLength="2"
          />
        </div>
        <div style={{ width: "50%" }}>
          <Input
            name="cvv"
            placeholder="e.g. 123"
            handler={handleCardCVVChange}
            errorMessage="Needs to have at least 3 characters."
            blurHandler={handleCardCVVBlur}
            error={cvvError}
            maxLength="3"
          />
        </div>
      </div>
      <button
        className="confirmButton"
        disabled={formHasErrors()}
        onClick={handleConfirmationButton}
      >
        Confirm
      </button>
    </div>
  );
};

export default Form;
