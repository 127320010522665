import { atom } from "nanostores";

const card = atom({});

const setCardNameholder = function setCardNameholder(value) {
  card.set({ ...card.get(), nameholder: value });
};
const setCardNumber = function setCardNumber(value) {
  card.set({ ...card.get(), number: value });
};
const setCardExpMonth = function setCardExpMonth(value) {
  card.set({ ...card.get(), expMonth: value });
};
const setCardExpYear = function setCardExpYear(value) {
  card.set({ ...card.get(), expYear: value });
};
const setCardCVV = function setCardCVV(value) {
  card.set({ ...card.get(), cvv: value });
};

export {
  card,
  setCardNameholder,
  setCardNumber,
  setCardExpMonth,
  setCardExpYear,
  setCardCVV,
};
