import { useStore } from "@nanostores/react";
import React from "react";
import cardlogo from "../assets/img/card-logo.svg";
import { card } from "../store/card";
import "./CardFront.css";

const CardFront = () => {
  const cardStore = useStore(card);

  const showDate = () => {
    return (
      cardStore.expMonth &&
      cardStore.expYear &&
      cardStore.expMonth.length + cardStore.expYear.length === 4
    );
  };

  return (
    <div className="cardFront">
      <img className="cardLogo" src={cardlogo} alt="card-logo" />
      <p className="cardNumber">{cardStore.number}</p>
      <div className="nameCad">
        <p>{cardStore.nameholder}</p>
        {showDate() && (
          <p>
            {cardStore.expMonth}/{cardStore.expYear}
          </p>
        )}

        {/* <p>{showDate() && cardStore.expMonth/cardStore.expYear}</p> */}
      </div>
    </div>
  );
};

export default CardFront;
