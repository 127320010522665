import { useStore } from "@nanostores/react";
import React from "react";
import { card } from "../store/card";
import "./CardBack.css";

const CardBack = () => {
  const cardStore = useStore(card);

  return (
    <div className="cardBack">
      <p className="cvv">{cardStore.cvv}</p>
    </div>
  );
};

export default CardBack;
